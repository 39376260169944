<template>
  <div>
    <Header :topic="'HOME'" />

<div class="container text-center" style="padding-top:12px">
  <h2>World Media</h2>
  <hr style="color:#9c9c9c;margin:1rem 0px;margin-left:auto;margin-right:auto;width:360px;" />

    <div class="row">
      <!--HeatMap></HeatMap-->
    </div>
    <div class="row">
        <div class="col-lg-6 col-xs-12">
          <Sunburst></Sunburst>
        </div>
        <div class="col-lg-6 col-xs-12">
          <LatestNews />
        </div>
    </div>

  <main role="main">
    <div class="container" style="padding-top: 0px;padding-bottom: 0px;">
      <div class="row">
        <div class="col-3">
        </div>
        <div class="col-3">
        </div>
      </div>

      <div class="row">
        <div class="col-lg-9 col-xs-12">
          <hr style="color:#9c9c9c;" />
          <h3>Business News</h3>
          <div v-for='(a, i) in articleList' :key='a'>
            <Article :article=a></Article>
            <ArticleAd v-if="(i % 8) == 4 && features.adverts==true"></ArticleAd>
          </div>
        </div>
        <div class="col-lg-3">
          <ins v-if="features.adverts==true" class="adsbygoogle" style="display:block" data-ad-format="fluid" data-ad-layout-key="-6t+ed+2i-1n-4w" data-ad-client="ca-pub-7129978262157669" data-ad-slot="1172211710"></ins>
          <hr style="color:#9c9c9c;" />
          <!--hr style="color:#9c9c9c;" /-->
          <h3>Keyword Trends</h3>
          <TrendKeyword />
          <hr style="color:#9c9c9c;" />
          <ins v-if="features.adverts==true" class="adsbygoogle" style="display:block" data-ad-format="fluid" data-ad-layout-key="-6t+ed+2i-1n-4w" data-ad-client="ca-pub-7129978262157669" data-ad-slot="1172211710"></ins>
          <hr style="color:#9c9c9c;" />
          <h3>Country Trends</h3>
          <TrendCountry />
        </div>
      </div>

    </div>

  </main>
  </div>
    <Footer></Footer>
      <vue-cookie-comply :preferences="preferences" />
  </div>
</template>

<style>
h2 {
    font-size: 36px;
    font-weight: 800;
}
p {
  text-align: justify;
  margin-bottom: 30px;
}

</style>

<script>
import * as Common from '../modules/common.js'
import Header from '../components/headers/header_logged_out.vue'
import Footer from '../components/headers/footer.vue'
//import HeatMap from '../components/widgets/heatmap.vue'
import Sunburst from '../components/widgets/topics_sunburst.vue'
import WorldSentiment from '../components/widgets/map.vue'
import moment from "moment"
import Article from '../components/widgets/article.vue'
import ArticleAd from '../components/widgets/article_ad.vue'
import LatestNews from '../components/widgets/latest_news.vue'
import TrendKeyword from '../components/widgets/trend_keyword.vue'
import TrendCountry from '../components/widgets/trend_country.vue'
import { useCookies } from "vue3-cookies";
var features = require('../modules/features.js')

export default {
  //runtimeCompiler: true,
  name: 'Index',
  components: {
    Header,
    Footer,
    //HeatMap,
    Sunburst,
    //WorldSentiment,
    Article,
    ArticleAd,
    LatestNews,
    TrendKeyword,
    TrendCountry,
},
  /*beforeCreate () {
      //this.$cc.on('consent-changed', () => {
      //    console.log('cookie consent changed, new user preferences:', this.$cc.getUserPreferences())
      //})
  },*/
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  created() {
    this.features = features.features;
    //this.$cc.show()
  },
  mounted() {
    var vm = this;
    var channel = 1;
    const urlParams = new URLSearchParams(window.location.search);
    const _channel = urlParams.get('channel');
    if (_channel != null) {
      channel = parseInt(_channel);
    }
    var startDate = moment().subtract(1,'week').format('YYYY-MM-DD');
    //All TECHNOLOGY FINANCIAL ENERGY CLIMATE TRAVEL ,"topic":"All"
    let data = JSON.stringify({"startDate":startDate,"page":0, "channel":channel, "size":100,"sort":1,"language":"EN"});

    Common.AJAX_GetJSON("https://api.pexley.com/articles/GetArticles/"+data, {}, function(data, success) {
      vm.articleList = data.result;
      //for (let i in vm.articleList) {
      //}
    });

    //Common.AJAX_GetJSON("https://api.pexley.com/articles/GetTopicHistogram/"+JSON.stringify({"startDate":startDate, "endDate":endDate, "interval":"week", "topic":""}), {}, function(data, status) {
    //});
  },
  methods: {
    changeSetting: function(index) {
      //data.settingsSelected = data.settings[index];
    },
    swapComponent: function(component) {
      console.log(component)
      this.RegisterComponent = component;
    },
    changeComponentState(state) {
      console.log(state);
      if (state == "VERIFY")
          this.swapComponent('VerifyEmail')
      else if (state == "MAIN")
          document.location.href = "/main/";
    },
  },
  data() {
    return {
      articleList:[],
      preferences:[
],
    };
  }     
}
</script>
