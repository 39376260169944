import { createRouter, createWebHashHistory } from 'vue-router'
import { createApp } from 'vue'

import Index from './index.vue'
import Login from './login.vue'
import { Vue3Mq } from "vue3-mq";

//import CookieConsent from 'vue-cookieconsent'

//import '../../node_modules/vue-cookieconsent/vendor/cookieconsent.css'
//import './theme/cookieconsent_custom.css' // custom cookie consent styles

// See: https://github.com/orestbida/cookieconsent#all-configuration-options
//const consentOptions = {autorun:true,current_lang: 'en',
//autoclear_cookies: true,
//page_scripts: true,}

/*
const routes = [
    { path: '/', name:'Root', component: Index },
    { path: '/game', name:'Game', component: Game },
]

const router = createRouter({
    history: true,//createWebHashHistory(),
    routes: routes,
})
*/

window.$ = window.jQuery = require('jquery');

//const { MqResponsive, Vue3Mq: Vue3MqPlugin } = Vue3Mq;
const app = createApp(Index)//.use(CookieConsent, consentOptions)
//app.use(VueNextSelect);
//app.use(router)
app.use(Vue3Mq)
app.mount('#main')
